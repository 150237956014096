import React from 'react';
import Component from './modern-cv-website';

function App() {
  return (
    <Component />
  );
}

export default App;
