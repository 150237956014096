import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Mail, Phone, MapPin, Github, Linkedin, ExternalLink, ChevronRight, ChevronDown } from 'lucide-react'

const sections: string[] = ['about', 'skills', 'experience', 'education', 'projects']

const fadeInUp: {
  initial: { opacity: number; y: number };
  animate: { opacity: number; y: number };
  transition: { duration: number };
} = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
}

const Component = () => {
  const [activeSection, setActiveSection] = useState<string>('about')

  const sections = ['about', 'skills', 'experience', 'education', 'projects']

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  }
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-gray-800 text-white p-4 sm:p-8">
      <div className="max-w-6xl mx-auto bg-gray-800 rounded-2xl shadow-2xl overflow-hidden">
        <div className="flex flex-col lg:flex-row">
          {/* Sidebar */}
          <aside className="lg:w-2/5 bg-gray-900 p-8">
            <div className="sticky top-8">
              <div className="mb-8">
                <h1 className="text-3xl font-bold text-center">Aleksandar Petkov</h1>
                <p className="text-xl text-gray-400 text-center">IT Developer</p>
              </div>
              <div className="space-y-4">
                <p className="flex items-center">
                  <Mail className="mr-2 text-gray-400" /> sa6ko4474@gmail.com
                </p>
                <p className="flex items-center">
                  <Phone className="mr-2 text-gray-400" /> +359 8968 60 247
                </p>
                <p className="flex items-center">
                  <MapPin className="mr-2 text-gray-400" /> Sofia, Bulgaria
                </p>
              </div>
              <div className="flex justify-center space-x-4 mt-8">
                <a href="https://github.com/Sashini336" className="text-gray-400 hover:text-white transition-colors">
                  <Github size={24} />
                </a>
              </div>
            </div>
          </aside>

          <main className="lg:w-3/5 p-8">
            <nav className="mb-8">
              <ul className="flex flex-wrap justify-center lg:justify-start gap-4">
                {sections.map((section) => (
                  <li key={section}>
                    <button
                      onClick={() => setActiveSection(section)}
                      className={`capitalize px-4 py-2 rounded-full text-sm font-medium transition-colors duration-300 ease-in-out
                        hover:bg-blue-500 hover:text-white`}
                    >
                      {section}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            <div className="space-y-12">
              <motion.section
                id="about"
                className={activeSection === 'about' ? 'block' : 'hidden'}
                {...fadeInUp}
              >
                <h2 className="text-2xl font-bold mb-4">About Me</h2>
                <div className="space-y-4 text-gray-300 leading-relaxed">
                  <p>
                    Motivated and adaptive IT professional with experience as an IT Developer at Planet.bg. 
                    Proficient in programming languages and technologies such as JavaScript, Python, and React.
                  </p>
                  <p>
                    Demonstrates problem-solving abilities and a commitment to continuous learning for effective 
                    technical solutions. Embraces new challenges and contributes to innovative IT projects.
                  </p>
                  <p>
                    Known for a complex problem-solving approach driven by an analytical mindset. Committed to 
                    achieving demanding development objectives within tight schedules while consistently producing 
                    impeccable code. A hardworking and passionate job seeker with strong organizational skills, 
                    seeking an entry-level position to contribute to the team's success in achieving company goals.
                  </p>
                </div>
              </motion.section>

              <motion.section
                id="skills"
                className={activeSection === 'skills' ? 'block' : 'hidden'}
                {...fadeInUp}
              >
                <h2 className="text-2xl font-bold mb-4">Skills</h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {['JavaScript', 'Python', 'Java', 'Node.js', 'React.js','Git', 'Docker', 'HTML', 'CSS'].map((skill) => (
                    <div key={skill} className="bg-gray-700 rounded-lg p-3 text-center">
                      {skill}
                    </div>
                  ))}
                </div>
              </motion.section>

              <motion.section
                id="experience"
                className={activeSection === 'experience' ? 'block' : 'hidden'}
                {...fadeInUp}
              >
                <h2 className="text-2xl font-bold mb-4">Experience</h2>
                <div className="space-y-8">
                  <div className="bg-gray-700 rounded-lg p-6">
                    <h3 className="text-xl font-semibold">IT Developer - Planet.bg</h3>
                    <p className="text-gray-400">June 2023 - Present</p>
                    <ul className="mt-4 list-disc list-inside text-gray-300">
                      <li>Maintained and supported web applications using front-end technologies and back-end frameworks.</li>
                      <li>Collaborated with cross-functional teams to implement and deploy new features.</li>
                      <li>Troubleshot and resolved technical issues, improving system uptime and user satisfaction.</li>
                    </ul>
                  </div>
                </div>
              </motion.section>

              <motion.section
                id="education"
                className={activeSection === 'education' ? 'block' : 'hidden'}
                {...fadeInUp}
              >
                <h2 className="text-2xl font-bold mb-4">Education</h2>
                <div className="space-y-6">
                  <div className="bg-gray-700 rounded-lg p-6">
                    <h3 className="text-xl font-semibold">University of Telecommunications (UTP)</h3>
                    <p className="text-gray-400">Software Engineering</p>
                    <p className="text-gray-300">Sofia, Bulgaria</p>
                  </div>
                  <div className="bg-gray-700 rounded-lg p-6">
                    <h3 className="text-xl font-semibold">Software University (SoftUni)</h3>
                    <ul className="mt-2 list-disc list-inside text-gray-300">
                      <li>Programming Basics with JavaScript</li>
                      <li>Programming Basics with Python</li>
                      <li>Programming Basics with Java</li>
                      <li>Programming Fundamentals</li>
                      <li>Technology Fundamentals</li>
                    </ul>
                  </div>
                </div>
              </motion.section>

              <motion.section
                id="projects"
                className={activeSection === 'projects' ? 'block' : 'hidden'}
                {...fadeInUp}
              >
                <h2 className="text-2xl font-bold mb-4">Projects</h2>
                <div className="space-y-6">
                  <div className="bg-gray-700 rounded-lg p-6">
                    <h3 className="text-xl font-semibold">Personal Portfolio Website</h3>
                    <p className="text-gray-300 mt-2">
                      Photography portfolio website with a modern design and responsive layout, built with React and Tailwind CSS with admin dashboard and login page.
                    </p>
                    <a href="https://portfolio-photography-lemon.vercel.app/" className="text-blue-400 hover:text-blue-300 mt-2 inline-block">
                      View Project <ExternalLink size={16} className="inline" />
                    </a>
                  </div>
                  <div className="bg-gray-700 rounded-lg p-6">
                    <h3 className="text-xl font-semibold">Barbershop Website</h3>
                    <p className="text-gray-300 mt-2">Barbershop website built with React and Tailwind CSS, featuring an admin dashboard and login page.</p>
                    <a href="https://barber-shop-tan-one.vercel.app/" className="text-blue-400 hover:text-blue-300 mt-2 inline-block">
                      View Project <ExternalLink size={16} className="inline" />
                    </a>
                  </div>
                </div>
              </motion.section>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default Component;